<template>
  <v-container fluid>
    <v-data-table
        :headers="headers"
        :items="statistic"
        :items-per-page="100"
        :sort-by="['balance']"
        :sort-desc="[false]"
        hide-default-footer
        dense
    >
      <template v-slot:item.id="{ item }">
        <a target="_blank" rel="noopener noreferrer"
           :href="`https://admin.mgid.com/cab/goodhits/clients?client_currency=&agency=&client_id=${item.id}&login=&email=&domain=&curator=&partner=&inviter=&accompany_manager=&wagesAll=&deposit=&subnet=&color=&periodSelectInterval=&btnsubmit=Filter`"
        >{{ item.id}}
        </a>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import api from '@/utils/api'
  import { IFACE_SPENDING_LAST_UPDATE } from '@/store/actions/iface'

  export default {
    name: 'Total',
    data () {
      return {
        headers: [
          {text: 'Network', value: 'network', sortable: false,},
          {text: 'Client ID', value: 'id', sortable: false,},
          {text: 'Name', value: 'name', sortable: false,},
          {text: 'Balance', value: 'balance'},
          {text: 'Credit', value: 'credit', sortable: false,},
          {text: 'Income', value: 'income'},
          {text: 'Today', value: 'spendingToday'},
          {text: 'Yesterday', value: 'spendingYesterday'},
          {text: 'Month', value: 'spendingThisMonth'},
        ],
        statistic: [],
      }
    },

    async mounted () {
      try {
        const res = await api.getSpending()
        this.$set(this, 'statistic', res.data.statistic)
        // window.console.error('Spending mounted RES', res)
        this.$store.commit(IFACE_SPENDING_LAST_UPDATE, Math.round((Date.now()/1000 - res.data.lastUpdate) / 60) )
      } catch (e) {
        window.console.error('Spending mounted', e)
      }
    }
  }
</script>

<style scoped>

</style>